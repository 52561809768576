module services {
    export module tariff {
        export class hsCodeService implements interfaces.tariff.IHsCodeService {
            static $inject = ["$resource", "ENV", "$http", "$timeout", "generalService"];
            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig, private $http: ng.IHttpService, private $timeout: ng.ITimeoutService, public generalService: interfaces.applicationcore.IGeneralService) {
            }

            getHSVersions():ng.IPromise<interfaces.tariff.HSVersions[]>{
                return this.$http({
                    method: 'GET',
                    responseType: 'json',
                    url: 'templates/json/HSVersions.json',
                }).then((response:any) => {
                    
                    return Promise.resolve(response.data);
                });
            }
        

            getSimplifiedExpertSearchResult(): ng.resource.IResourceClass<interfaces.tariff.INationalMeasureCodeLookup> {
                return this.$resource<interfaces.tariff.INationalMeasureCodeLookup>(this.ENV.DSP_URL + "HsCode/GetSimplifiedExpertSearchResult", {
                    type: "@type",
                    countryId: "@countryId",
                    simulationDate: "@simulationDate",
                    exclusions: "@exclusions",
                    dutyTypeId: "@dutyTypeId",
                    code: "@code",
                    description: "@description",
                    linkedCode: "@linkedCode"
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            ///simulationDate format = this.simulationDate.format("YYYYMMDDTHH:mm")
            getTariffBookExcel(countryId: number, simulationDate: string, localeCode: string, code: string): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'HsCode/GetTariffBookExcel?';

                url += 'countryId=' + countryId + "&simulationDate=" + simulationDate + "&localeCode=" + localeCode + "&code=" + code;

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'tariffBookDownload.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            getTariffBookLocales(): ng.resource.IResourceClass<interfaces.tariff.ITariffBookLocale> {
                return this.$resource<interfaces.tariff.ITariffBookLocale>(this.ENV.DSP_URL + "HsCode/GetTariffBookLocales", {
                }, {
                        get: {
                        method: 'GET',
                        isArray: true
                    }
                });
            }

            getDetail(): ng.resource.IResourceClass<interfaces.tariff.INationalMeasureCodeDetail> {
                return this.$resource<interfaces.tariff.INationalMeasureCodeDetail>(this.ENV.DSP_URL + "HsCode/GetDetail", {
                    countryId: "@countryId",
                    codeType: "@codeType",
                    simulationDate: "@simulationDate",
                    code: "@code",
                    showAll: "@showAll",
                }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            getDetailPDF(countryId: number, codeType:number, simulationDate:string, code: string, showAll: boolean, showPR: boolean): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'HsCode/GetDetailPDF?';

                url += 'countryId=' + countryId + '&codeType=' + codeType + '&simulationDate='+simulationDate + '&code=' + code + '&showAll='+showAll + '&showPR=true'

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'TariffCodeDetail.pdf';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }
        }
    }
    angular.module("app").service("hsCodeService", services.tariff.hsCodeService);
}